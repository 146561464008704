import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import 'typeface-raleway'
import '../styles/main.scss'

const LocaleContext = React.createContext()

const Layout = (location) => {
  if (location.pathname === '/offline-plugin-app-shell-fallback') return null
  const children = location.children
  const pageContext = location.pageContext
  return (
    <StaticQuery
      query={graphql`
        fragment menuProps on SanityChemin {
          slug {
            _type
            fr {
              current
            }
            en {
              current
            }
          }
          page {
            title {
              fr
              en
            }
          }
        }

        query SiteSettingsQuery {
          site {
            siteMetadata {
              title
            }
          }
          sanitySiteMenus {
            mainNavigation {
              ...menuProps
            }
            footerNavigation {
              ...menuProps
            }
            legalNavigation {
              ...menuProps
            }
          }
        }
      `}
      render={data => {
        const locale = pageContext ? pageContext.locale : "fr"
        const metaDescriptionDefault = {
          "fr": "Wilhem Latchoumia est un pianiste français",
          "en": "Wilhem Latchoumia is a french pianist",
          }
        const pageTitle = pageContext ? pageContext.page ? pageContext.page.title : "" : ""
        const pageMetaTitle = pageContext ? pageContext.page ? pageContext.page.seo ? pageContext.page.seo.metaTitle : pageTitle : pageTitle : pageTitle
        const pageMetaDesc = pageContext ? pageContext.page ? pageContext.page.seo ? pageContext.page.seo.metaDescription : metaDescriptionDefault : metaDescriptionDefault : metaDescriptionDefault
        const pageSlug = pageContext ? pageContext.slug : ""
        let wrapperClasses = []
        wrapperClasses.push(`wrapper`)
        wrapperClasses.push(`is-locale-${locale}`)
        if (pageSlug) {
          pageSlug.fr.current === '/' ? wrapperClasses.push(`is-page-home`) : wrapperClasses.push(`is-page-${pageSlug.fr.current}`)
        } else {
          wrapperClasses.push(`is-page-404`)
        }
        return (
        <LocaleContext.Provider value={{ locale, pageSlug }}>
          <div className={wrapperClasses.join(' ')}>
            <Header siteTitle={data.site.siteMetadata.title} elementsMenu={data.sanitySiteMenus.mainNavigation} pageSlug={pageSlug} location={location} pageMetaTitle={pageMetaTitle} pageMetaDesc={pageMetaDesc} />
            <main className="main">
              {children}
            </main>
            <Footer menuFooter={data.sanitySiteMenus.footerNavigation} menuLegal={data.sanitySiteMenus.legalNavigation} />
          </div>
        </LocaleContext.Provider>
      )}}
    />
  )
}

export { Layout as default, LocaleContext }
