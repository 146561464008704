import React from 'react'
import { Link } from 'gatsby'
import LogoBlanc from '../svg/WilhemLatchoumia_blanc.svg'
import LogoNoir from '../svg/WilhemLatchoumia_noir.svg'
import { LocaleContext } from './layout'
import SEO from './seo'
import { FaBars, FaTimes } from 'react-icons/fa'
import { removeTrailingSlash } from '../util'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.onBurgerClick = this.onBurgerClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)
  }

  onBurgerClick() {
    if (typeof document != `undefined`) {
      const menuPrincipal = document.getElementById('menuPrincipal')
      const html = document.querySelector('html')

      menuPrincipal.closest('.navbar').classList.add('is-visible')
      html.classList.add('is-clipped')
    }
  }

  onCloseClick() {
    if (typeof document != `undefined`) {
      const menuPrincipal = document.getElementById('menuPrincipal')
      const html = document.querySelector('html')

      menuPrincipal.closest('.navbar').classList.remove('is-visible')
      html.classList.remove('is-clipped')
    }
  }

  onLinkClick() {
    this.onCloseClick()
  }

  render() {
    if (this.props.location.pathname === '/offline-plugin-app-shell-fallback') return null
    return (
      <LocaleContext.Consumer>
        {({ locale, pageSlug }) => {
          const isDefaultLang = locale === 'fr'
          let correspondingPageLink = isDefaultLang ? `/en/` : `/`
          const currentSlug = pageSlug ? pageSlug[locale].current : ''
          const oppositeLocale = isDefaultLang ? 'en' : 'fr'
          const correspondingSlug = pageSlug ? pageSlug[oppositeLocale].current : ''
          correspondingPageLink += currentSlug !== '/' ? correspondingSlug : ''

          return (
            <header className={
              currentSlug === '/' ?
              'header site-header is-absolute' : 'header site-header'
            }>
              <SEO
                title={this.props.pageMetaTitle[locale]}
                description={this.props.pageMetaDesc[locale]}
                lang={locale}
                pageSlug={pageSlug}
              />
              <div className="container">
                <nav className="navbar">
                  <button className="navbar-burger burger" onClick={this.onBurgerClick} aria-label="menu" aria-expanded="false" data-target="menuPrincipal">
                    <FaBars />
                  </button>
                  <div className="navbar-menu" id="menuPrincipal">
                    <span className="navbar-close close" onClick={this.onCloseClick}>
                      <FaTimes />
                    </span>
                    <div className="navbar-end">
                      { this.props.elementsMenu.map( (elementMenu, index) => {
                        const slug = elementMenu.slug[locale].current
                        const isActive = slug === currentSlug
                        const pageTitle = elementMenu.page.title[locale]
                        const href = isDefaultLang ? `/${slug}` : `/${locale}/${slug}`
                        const menuItemClasses = `navbar-item ${ isActive ? 'is-active' : ''}`
                        return <Link className={menuItemClasses} key={index} to={href} onClick={this.onLinkClick}>{pageTitle}</Link>
                      })}
                      <Link className="navbar-item navbar-lang" to={ removeTrailingSlash(correspondingPageLink) } onClick={this.onLinkClick}>{ isDefaultLang ? `en` : `fr`}</Link>
                    </div>
                  </div>
                </nav>
                <Link to={ isDefaultLang ? '/' : `/${locale}` } className="site-logo">
                  {
                    currentSlug === '/' ?
                    <LogoBlanc /> : <LogoNoir />
                  }
                  
                </Link>
              </div>
            </header>
          )}
        }
      </LocaleContext.Consumer>
    )
  }
}

export default Header
