exports.onServiceWorkerUpdateReady = () => window.location.reload(true)
exports.onClientEntry = () => {
  if (!Object.prototype.forEach) {
    // eslint-disable-next-line
    Object.defineProperty(Object.prototype, 'forEach', {
      value: function (callback, thisArg) {
        if (this === null) {
          throw new TypeError('Not an object');
        }
        thisArg = thisArg || window;
        for (var key in this) {
          if (this.hasOwnProperty(key)) {
            callback.call(thisArg, this[key], key, this);
          }
        }
      }
    });
  }

  if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;

  if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
      var el = this;
      if (!document.documentElement.contains(el)) return null;
      do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1); 
      return null;
    };
}
