// Use a little helper function to remove trailing slashes from paths
exports.removeTrailingSlash = path =>
  path === `/` ? path : path.replace(/\/$/, ``)

exports.createLocaleTextGetter = languageCode => {
  const languages = [languageCode, "fr"] // last language in array is default;
  const localize = value => {
    if (Array.isArray(value)) {
      return value.map(v => localize(v, languages))
    } else if (typeof value == "object" && value != null) {
      if (/^locale[A-Z]/.test(value._type)) {
        const language = languages.find(lang => value[lang])
        return value[language]
      }

      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages)
        return result
      }, {})
    }
    return value
  }

  return localize
}

exports.matchSectionWithRawSection = (sections, rawSections) => {
  return sections.map(section => {
    let matchingRawSection
    rawSections.forEach(rawSection => {
      if (rawSection._key === section._key) {
        matchingRawSection = rawSection
      }
    })
    return {
      _key:  section._key,
      _type: section._type,
      data: section,
      rawData: matchingRawSection
    }
  })
}

exports.makeExcerpt = (blocks = [], size = 255) => {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    .join(' ')
    .substring(0, size) + '...'
}
