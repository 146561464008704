import React from 'react'
import { Link } from 'gatsby'
import Logo from '../svg/WilhemLatchoumia_noir.svg'
import { LocaleContext } from './layout'
import { FaFacebookSquare } from 'react-icons/fa'

const Footer = ({ menuFooter, menuLegal}) => {
  const { locale } = React.useContext(LocaleContext)
  const isDefaultLang = locale === 'fr'
  return (
    <footer className="section site-footer">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-5">
            <Link to="/" className="has-no-style site-logo">
              <Logo />
            </Link>
          </div>
          <div className="column is-7">
            <nav className="navbar">
              <div className="navbar-menu">
                <div className="navbar-end">
                  { menuFooter.map( (elementMenu, index) => {
                    const slug = elementMenu.slug[locale].current
                    const pageTitle = elementMenu.page.title[locale]
                    const href = isDefaultLang ? `/${slug}` : `/${locale}/${slug}`
                    return <Link className="navbar-item" key={index} to={href}>{pageTitle}</Link>
                  })}
                </div>
              </div>
            </nav>
            <nav className="navbar">
              <div className="navbar-menu">
                <div className="navbar-end">
                  { menuLegal.map( (elementMenu, index) => {
                    const slug = elementMenu.slug[locale].current
                    const pageTitle = elementMenu.page.title[locale]
                    const href = isDefaultLang ? `/${slug}` : `/${locale}/${slug}`
                    return <Link className="navbar-item" key={index} to={href}>{pageTitle}</Link>
                  })}
                </div>
              </div>
            </nav>
            <nav className="navbar">
              <div className="navbar-menu">
                <div className="navbar-end">
                  <a href="https://www.facebook.com" className="navbar-item">
                    <span className="icon is-large">
                      <FaFacebookSquare/>
                    </span>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="credits">
        Design & Développement : <a href="https://www.cerealconcept.com">Cereal Concept</a>
      </div>
    </footer>
  )
}

export default Footer
