/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { removeTrailingSlash } from '../util'

function makeHreflang(pageSlug, site) {
  if ( ! pageSlug ) return []
  if (pageSlug['fr'].current === '/') {
    return [
      {
        rel: 'alternate',
        href: removeTrailingSlash(site.siteMetadata.siteUrl + '/en'),
        hreflang: 'en',
      },
      {
        rel: 'alternate',
        href: removeTrailingSlash(site.siteMetadata.siteUrl),
        hreflang: 'fr',
      }
    ]
  } else {
    return [
      {
        rel: 'alternate',
        href: removeTrailingSlash(site.siteMetadata.siteUrl + '/en/' + pageSlug['en'].current),
        hreflang: 'en',
      },
      {
        rel: 'alternate',
        href: removeTrailingSlash(site.siteMetadata.siteUrl + '/' + pageSlug['fr'].current),
        hreflang: 'fr',
      }
    ]
  }
}

function SEO({ description, lang, meta, keywords, title, pageSlug }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      link={makeHreflang(pageSlug, site)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
